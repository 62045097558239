<form [formGroup]="form" (submit)="joinSession()">
  <label for="name">Your name</label>
  <input id="name" type="text" [formControl]="name">
  <app-form-errors [control]="name">
    <ng-template appFormErrorMsg="required" let-info>Your name is required</ng-template>
  </app-form-errors>

  <button [disabled]="form.invalid || loadingState.loading">Join session</button>

  <app-loading-spinner *ngIf="loadingState.loading"></app-loading-spinner>
  <app-error-message *ngIf="loadingState.error" [error]="loadingState.error"></app-error-message>
</form>
