<h2>Create new estimation session</h2>
<p>
  After you have created a session, you can invite your team members and start estimating topics.
</p>
<form [formGroup]="form" (submit)="createSession()">

  <label for="sessionName">Session name</label>
  <input id="sessionName" type="text" formControlName="name" autofocus placeholder="Project X" autocomplete="off">
  <app-form-errors [control]="name">
    <ng-template appFormErrorMsg="required" let-info>The session name is required</ng-template>
  </app-form-errors>

  <button [disabled]="form.invalid || loadingState.loading">Create session</button>

  <app-loading-spinner *ngIf="loadingState.loading"></app-loading-spinner>
  <app-error-message *ngIf="loadingState.error" [error]="loadingState.error"></app-error-message>

  <p>
    Created sessions will expire 90 days after inactivity.
  </p>

</form>
