<header>
  <nav class="main-nav">
    <a [routerLink]="'e'" routerLinkActive="active">Estimation</a>
  </nav>
</header>
<main>
<router-outlet></router-outlet>
</main>
<footer>
  <div class="footer">
    <a href="https://github.com/dhcode/scrum-tools" target="_blank">Scrum Tools</a> by Dominik Herbst
  </div>
</footer>
