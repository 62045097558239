<ng-container *ngIf="loadingState.loaded && session">
  <div class="basic-info">
    <div>
      <h3>Session: {{session.name}}</h3>
      <pre>{{session.description}}</pre>
      <div class="join-link">
        {{joinLink}}
        <button class="btn small secondary" (click)="copyMemberUrl()">Copy</button>
      </div>
    </div>
    <img class="qrCode" [src]="qrCode" alt="QR Code to join">
  </div>

  <div *ngIf="currentTopic">

    <h1>
      <ng-container *ngIf="!currentTopic.endedAt">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"
             xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 512 512">
          <g>
            <path
              d="m453.5,460.2h-19.7c-7.4-72.8-52.1-165.1-115.4-204.2 63.3-39.1 108-131.3 115.4-204.2h19.7c11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-395c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h19.7c7.4,72.8 52.1,165.1 115.4,204.2-63.3,39.1-108,131.4-115.4,204.2h-19.7c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h394.9c11.3,0 20.4-9.1 20.4-20.4 0.1-11.3-9.1-20.4-20.3-20.4zm-334.1-408.4h273.2c-9.6,76.5-69.4,182.2-136.6,182.2-67.2,0-127-105.7-136.6-182.2zm136.6,226.2c67.2,0 127,105.7 136.6,182.2h-273.2c9.6-76.5 69.4-182.2 136.6-182.2z"/>
          </g>
        </svg>
      </ng-container>
      <ng-container *ngIf="currentTopic.endedAt">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 400 400" enable-background="new 0 0 400 400">
          <g>
            <path d="M199.996,0C89.713,0,0,89.72,0,200s89.713,200,199.996,200S400,310.28,400,200S310.279,0,199.996,0z M199.996,373.77
			C104.18,373.77,26.23,295.816,26.23,200c0-95.817,77.949-173.769,173.766-173.769c95.817,0,173.771,77.953,173.771,173.769
			C373.768,295.816,295.812,373.77,199.996,373.77z"/>
            <path d="M272.406,134.526L169.275,237.652l-41.689-41.68c-5.123-5.117-13.422-5.12-18.545,0.003
			c-5.125,5.125-5.125,13.425,0,18.548l50.963,50.955c2.561,2.558,5.916,3.838,9.271,3.838s6.719-1.28,9.279-3.842
			c0.008-0.011,0.014-0.022,0.027-0.035L290.95,153.071c5.125-5.12,5.125-13.426,0-18.546
			C285.828,129.402,277.523,129.402,272.406,134.526z"/>
          </g>

        </svg>

      </ng-container>

      <span class="topic-heading">
        {{currentTopic.name}}
      </span>
    </h1>
    <p class="small">
      Started
      <app-time-ago [date]="currentTopic.startedAt"></app-time-ago>
      <ng-container *ngIf="currentTopic.endedAt">
        and ended
        <app-time-ago [date]="currentTopic.endedAt"></app-time-ago>
      </ng-container>

    </p>

    <app-topic-result [topic]="currentTopic" [showOptions]="true"></app-topic-result>

    <button *ngIf="session.activeTopic" type="button" class="btn" (click)="endVote()">End vote & reveal</button>
  </div>

  <div *ngIf="!session.activeTopic">
    <h3>Create new topic</h3>
    <app-create-topic-form [session]="session"></app-create-topic-form>
  </div>

  <app-members [session]="session"
               (remove)="removeMember($event)"></app-members>

  <br>
  <p class="small">
    Sessions expire 90 days after inactivity. Within this time frame you should save the master link in your bookmarks,
    to gain access to this session later.
  </p>
  <button type="button" class="btn secondary small" (click)="copyMasterUrl()">Copy master link</button>


</ng-container>

<app-loading-spinner *ngIf="loadingState.loading"></app-loading-spinner>
<app-error-message *ngIf="loadingState.error" [error]="loadingState.error"></app-error-message>
