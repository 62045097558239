
<p *ngIf="showOptions">
  Options<br>
  <span *ngFor="let opt of topic.options" class="option" [class.active]="popularVote === opt">{{opt || '?'}}</span>
</p>

<h4 *ngIf="votes.length">{{topic.votes.length}} votes received</h4>
<h4 *ngIf="!votes.length">Votes</h4>

<div *ngIf="!votes.length" class="primary-note">No votes received yet</div>

<div *ngIf="votes.length" class="votes">
  <div *ngFor="let vote of votes; trackBy: byId" class="vote">
    <div class="name">{{vote.memberName}}</div>
    <div class="result" *ngIf="vote.vote === null">✓</div>
    <div class="result" *ngIf="vote.vote !== null">
      {{vote.vote ? vote.vote : '?'}}
    </div>
  </div>
</div>
