<h3>{{onlineMembers}} / {{session.members.length}} Online Members</h3>

<div *ngIf="session.members?.length" class="members">
  <app-member-item *ngFor="let member of session.members; trackBy: byId"
                   [member]="member"
                   [isAdmin]="isAdmin"
                   [(isDetailed)]="isDetailed"
                   (remove)="remove.next(member)"></app-member-item>
</div>

<div *ngIf="!session.members?.length" class="primary-note">
  No members have joined this session.
</div>
