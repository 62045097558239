<ng-container *ngIf="loadingState.loaded && session">
  <div class="basic-info">
    <h3>Session: {{session.name}}</h3>
    <pre>{{session.description}}</pre>
  </div>

  <div *ngIf="session.activeTopic" class="topic-info">

    <h1 class="topic-heading">{{session.activeTopic.name}}</h1>
    <p class="small">Started <app-time-ago [date]="session.activeTopic.startedAt"></app-time-ago></p>

    <div class="vote-options" *ngIf="!voteLoadingState.loading">
      <button *ngFor="let opt of session.activeTopic.options"
              type="button"
              class="btn"
              [disabled]="!memberId"
              [class.active]="vote === opt"
              (click)="addVote(opt)">{{opt ? opt : '?'}}</button>
    </div>
    <app-loading-spinner *ngIf="voteLoadingState.loading"></app-loading-spinner>
    <app-error-message *ngIf="voteLoadingState.error" [error]="voteLoadingState.error"></app-error-message>

  </div>

  <div *ngIf="currentTopic && !session.activeTopic" class="topic-info">
    <h1>{{currentTopic.name}}</h1>
    <p class="small">Started <app-time-ago [date]="currentTopic.startedAt"></app-time-ago> and ended <app-time-ago [date]="currentTopic.endedAt"></app-time-ago></p>
  </div>
  <app-topic-result *ngIf="currentTopic" [topic]="currentTopic"></app-topic-result>

  <div class="members">
    <app-members [session]="session"></app-members>

  </div>

  <app-join-form *ngIf="!memberId" [session]="session" (joined)="initMember()"></app-join-form>

  <p>
    <button *ngIf="memberId" type="button" class="btn secondary" (click)="leaveSession()">Leave session</button>
  </p>


</ng-container>

<app-loading-spinner *ngIf="loadingState.loading"></app-loading-spinner>
<app-error-message *ngIf="loadingState.error" [error]="loadingState.error"></app-error-message>
